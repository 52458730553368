import { Grid, Radio } from '@material-ui/core';
import React from 'react';
import './App.css';
import { Browser } from './components/Browser';
import { Endpoint } from './components/Endpoint';
import { Evaluator } from './components/Evaluator';

interface State {
  endpoint: Endpoint;
}

export class App extends React.Component<{}, State> {
  constructor(props: {}) {
    super(props);
    this.state = {
      endpoint: window.location.href.startsWith('http://localhost')
        ? Endpoint.Prod
        : Endpoint.Default,
    };
  }

  render() {
    return (
      <div className="App">
        <header className="App-header">
          <h1 className="App-title">Functional Infrastructure code editor</h1>
          <Radio
            checked={this.state.endpoint === Endpoint.Default}
            onChange={this.updateEndpoint(Endpoint.Default)}
          >
            {' '}
            Default{' '}
          </Radio>
          <Radio
            checked={this.state.endpoint === Endpoint.Prod}
            onChange={this.updateEndpoint(Endpoint.Prod)}
          >
            {' '}
            Prod{' '}
          </Radio>
          <Radio
            checked={this.state.endpoint === Endpoint.Localhost}
            onChange={this.updateEndpoint(Endpoint.Localhost)}
          >
            {' '}
            Localhost{' '}
          </Radio>
        </header>
        <Grid container={true} direction="column">
          <Grid item={true}>
            {' '}
            <Evaluator endpoint={this.state.endpoint} />{' '}
          </Grid>
          <Grid item={true}>
            {' '}
            <Browser endpoint={this.state.endpoint} />{' '}
          </Grid>
        </Grid>
      </div>
    );
  }

  private updateEndpoint = (newEndpoint: Endpoint) => (
    event: React.ChangeEvent<{}>
  ) => {
    this.setState({ endpoint: newEndpoint });
  };
}
