export enum Endpoint {
  Default,
  Localhost,
  Prod,
}

export function getBackendEndpoint(endpoint: Endpoint): string {
  switch (endpoint) {
    case Endpoint.Default:
      return '';
    case Endpoint.Localhost:
      return 'http://localhost:3001';
    case Endpoint.Prod:
      return 'https://www.benjijones.com';
  }
}

export function getMode(endpoint: Endpoint): RequestMode | undefined {
  switch (endpoint) {
    case Endpoint.Default:
      return undefined;
    case Endpoint.Localhost:
      return 'cors';
    case Endpoint.Prod:
      return 'cors';
  }
}
