import { DataType, mapDataType, prettyPrintDataType } from './DataType';
import { Expr, mapExpr, prettyPrintExpr } from './Expr';

export interface BoundExpr<B> {
  tag: 'BoundExpr';
  contents: Expr<B>;
}

export interface BoundDataType<B> {
  tag: 'BoundDataType';
  contents: DataType<B>;
}

export type Bound<B> = BoundExpr<B> | BoundDataType<B>;

export function mapBound<A, B>(f: (_: A) => B, bound: Bound<A>): Bound<B> {
  switch (bound.tag) {
    case 'BoundExpr':
      return { tag: 'BoundExpr', contents: mapExpr(f, bound.contents) };
    case 'BoundDataType':
      return { tag: 'BoundDataType', contents: mapDataType(f, bound.contents) };
  }
}

export function prettyPrintBound(bound: Bound<string>): string {
  switch (bound.tag) {
    case 'BoundExpr':
      return prettyPrintExpr(bound.contents);
    case 'BoundDataType':
      return prettyPrintDataType(bound.contents);
  }
}
