import * as React from 'react';
import { Binder } from './Binder';

export interface Variable<B> {
  tag: 'Variable';
  contents: B;
}

export interface Product<B> {
  tag: 'Product';
  contents: [B, B[]];
}

export type Pattern<B> = Variable<B> | Product<B>;

export interface DataRef<B> {
  tag: 'DataRef';
  contents: [B, Array<Expr<B>>];
}

export interface Case<B> {
  tag: 'Case';
  contents: [Expr<B>, Array<CaseArm<B>>];
}

export interface Let<B> {
  tag: 'Let';
  contents: [B, Expr<B>, Expr<B>];
}

export interface LetRef<B> {
  tag: 'LetRef';
  contents: B;
}

export interface Lambda<B> {
  tag: 'Lambda';
  contents: [B, Expr<B>];
}

export interface Apply<B> {
  tag: 'Apply';
  contents: [Expr<B>, Expr<B>];
}

export interface Primitive<B> {
  tag: 'Primitive';
  contents: Prim<B>;
}

export type Prim<B> = NumberLiteral | Binary<B> | ByteStringLiteral;

export interface NumberLiteral {
  tag: 'NumberLiteral';
  contents: number;
}

export interface Binary<B> {
  tag: 'Binary';
  contents: [BinOp, Expr<B>, Expr<B>];
}

export interface ByteStringLiteral {
  tag: 'ByteStringLiteral';
  contents: string;
}

export type Expr<B> =
  | DataRef<B>
  | Case<B>
  | Let<B>
  | LetRef<B>
  | Lambda<B>
  | Apply<B>
  | Primitive<B>;

export type CaseArm<B> = [Pattern<B>, Expr<B>];

export interface Add {
  tag: 'Add';
}

export interface Sub {
  tag: 'Sub';
}

export type BinOp = Add | Sub;

export function mapExpr<A, B>(f: (_: A) => B, expr: Expr<A>): Expr<B> {
  switch (expr.tag) {
    case 'DataRef':
      return {
        tag: 'DataRef',
        contents: [
          f(expr.contents[0]),
          expr.contents[1].map(it => mapExpr(f, it)),
        ],
      };
    case 'Case':
      return {
        tag: 'Case',
        contents: [
          mapExpr(f, expr.contents[0]),
          expr.contents[1].map(arm => mapCaseArm(f, arm)),
        ],
      };
    case 'Let':
      return {
        tag: 'Let',
        contents: [
          f(expr.contents[0]),
          mapExpr(f, expr.contents[1]),
          mapExpr(f, expr.contents[2]),
        ],
      };
    case 'LetRef':
      return { tag: 'LetRef', contents: f(expr.contents) };
    case 'Lambda':
      return {
        tag: 'Lambda',
        contents: [f(expr.contents[0]), mapExpr(f, expr.contents[1])],
      };
    case 'Apply':
      return {
        tag: 'Apply',
        contents: [mapExpr(f, expr.contents[0]), mapExpr(f, expr.contents[1])],
      };
    case 'Primitive':
      return { tag: 'Primitive', contents: mapPrimitive(f, expr.contents) };
  }
}

export function mapPattern<A, B>(
  f: (_: A) => B,
  pattern: Pattern<A>
): Pattern<B> {
  switch (pattern.tag) {
    case 'Variable':
      return { tag: 'Variable', contents: f(pattern.contents) };
    case 'Product':
      return {
        tag: 'Product',
        contents: [f(pattern.contents[0]), pattern.contents[1].map(f)],
      };
  }
}

export function mapCaseArm<A, B>(
  f: (_: A) => B,
  caseArm: CaseArm<A>
): CaseArm<B> {
  return [mapPattern(f, caseArm[0]), mapExpr(f, caseArm[1])];
}

export function mapPrimitive<A, B>(
  f: (_: A) => B,
  primitive: Prim<A>
): Prim<B> {
  switch (primitive.tag) {
    case 'NumberLiteral':
      return { tag: 'NumberLiteral', contents: primitive.contents };
    case 'Binary':
      return {
        tag: 'Binary',
        contents: [
          primitive.contents[0],
          mapExpr(f, primitive.contents[1]),
          mapExpr(f, primitive.contents[2]),
        ],
      };
    case 'ByteStringLiteral':
      return { tag: 'ByteStringLiteral', contents: primitive.contents };
  }
}

export function prettyPrintExpr(expr: Expr<string>): string {
  switch (expr.tag) {
    case 'DataRef':
      return (
        expr.contents[0] +
        ' ' +
        expr.contents[1].map(it => prettyPrintExpr(it)).join(' ')
      );
    case 'Case':
      return (
        'case ' +
        prettyPrintExpr(expr.contents[0]) +
        ' of {\n' +
        expr.contents[1].map(it => prettyPrintCaseArm(it)).join('\n  ') +
        '\n' +
        '}'
      );
    case 'Let':
      return (
        'let ' +
        expr.contents[0] +
        ' = ' +
        prettyPrintExpr(expr.contents[1]) +
        ' in ' +
        prettyPrintExpr(expr.contents[2])
      );
    case 'LetRef':
      return expr.contents;
    case 'Lambda':
      return (
        '\\' + expr.contents[0] + ' -> ' + prettyPrintExpr(expr.contents[1])
      );
    case 'Apply':
      return (
        '(' +
        prettyPrintExpr(expr.contents[0]) +
        ' ' +
        prettyPrintExpr(expr.contents[1]) +
        ')'
      );
    case 'Primitive':
      return prettyPrintPrimitive(expr.contents);
  }
}

function prettyPrintCaseArm(caseArm: CaseArm<string>): string {
  return prettyPrintPattern(caseArm[0]) + ' -> ' + prettyPrintExpr(caseArm[1]);
}

function prettyPrintPattern(pattern: Pattern<string>): string {
  switch (pattern.tag) {
    case 'Variable':
      return pattern.contents;
    case 'Product':
      return pattern.contents[0] + ' ' + pattern.contents[1].join(' ');
  }
}

function prettyPrintPrimitive(primitive: Prim<string>): string {
  switch (primitive.tag) {
    case 'NumberLiteral':
      return primitive.contents.toString();
    case 'Binary':
      return (
        prettyPrintExpr(primitive.contents[1]) +
        ' ' +
        prettyPrintBinOp(primitive.contents[0]) +
        ' ' +
        prettyPrintExpr(primitive.contents[2])
      );
    case 'ByteStringLiteral':
      return '"' + atob(primitive.contents) + '"';
  }
}

function prettyPrintBinOp(binOp: BinOp): string {
  switch (binOp.tag) {
    case 'Add':
      return '+';
    case 'Sub':
      return '-';
  }
}

export function getJSX(expr: Expr<Binder>): null | JSX.Element {
  const httpResponse = getDataRefWithGuid(
    '00000000-0000-0000-0000-0000159f1de6',
    expr
  );
  if (
    httpResponse &&
    httpResponse.contents[1] &&
    httpResponse.contents[1].length > 1
  ) {
    const primitive = getPrimitive(httpResponse.contents[1][1]);
    if (primitive) {
      const byteStringLiteral = getByteStringLiteral(primitive.contents);
      if (byteStringLiteral) {
        const jsxElement = document.createElement('div');
        jsxElement.appendChild(
          document
            .createRange()
            .createContextualFragment(atob(byteStringLiteral.contents))
        );
        return (
          <div dangerouslySetInnerHTML={{ __html: jsxElement.innerHTML }} />
        );
      }
    }
  }
  return null;
}

function getDataRefWithGuid(
  guid: string,
  expr: Expr<Binder>
): DataRef<Binder> | null {
  switch (expr.tag) {
    case 'DataRef':
      if (expr.contents[0].guid === guid) {
        return expr;
      } else {
        return null;
      }
    default:
      return null;
  }
}

function getPrimitive<B>(expr: Expr<B>): Primitive<B> | null {
  switch (expr.tag) {
    case 'Primitive':
      return expr;
    default:
      return null;
  }
}

function getByteStringLiteral<B>(primitive: Prim<B>): ByteStringLiteral | null {
  switch (primitive.tag) {
    case 'ByteStringLiteral':
      return primitive;
    default:
      return null;
  }
}
