export interface TypeBinder<B> {
  tag: 'TypeBinder';
  contents: B;
}

export interface TypeSum<B> {
  tag: 'TypeSum';
  contents: Array<DataType<B>>;
}

export interface TypeProduct<B> {
  tag: 'TypeProduct';
  contents: Array<DataType<B>>;
}

export type DataType<B> = TypeBinder<B> | TypeSum<B> | TypeProduct<B>;

export function mapDataType<A, B>(
  f: (_: A) => B,
  dataType: DataType<A>
): DataType<B> {
  switch (dataType.tag) {
    case 'TypeBinder':
      return { tag: 'TypeBinder', contents: f(dataType.contents) };
    case 'TypeSum':
      return {
        tag: 'TypeSum',
        contents: dataType.contents.map(it => mapDataType(f, it)),
      };
    case 'TypeProduct':
      return {
        tag: 'TypeProduct',
        contents: dataType.contents.map(it => mapDataType(f, it)),
      };
  }
}

export function prettyPrintDataType(dataType: DataType<string>): string {
  switch (dataType.tag) {
    case 'TypeBinder':
      return dataType.contents;
    case 'TypeSum':
      return (
        'Sum(' + dataType.contents.map(it => prettyPrintDataType(it)) + ')'
      );
    case 'TypeProduct':
      return (
        'Product(' + dataType.contents.map(it => prettyPrintDataType(it)) + ')'
      );
  }
}
