import { List, ListItem, Toolbar } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from '@material-ui/core/styles';
import * as React from 'react';
import * as Uuid from 'uuid';
import { Binder } from './Binder';
import { Bound } from './Bound';
import { Endpoint, getBackendEndpoint, getMode } from './Endpoint';
import { ErrorOr } from './ErrorOr';
import { Expr, getJSX, mapExpr, prettyPrintExpr } from './Expr';
import {
  IdentifyBindingResponse,
  IdentifyExpressionResponse,
} from './Identify';

interface Props extends WithStyles<typeof styles> {
  endpoint: Endpoint;
}

interface State {
  evaluatorInputText: string;
  parseState?: ErrorOr<
    Bound<string> | { tag: 'ParsedExpression'; contents: Expr<string> }
  >;
  identifyState?: IdentifyBindingResponse | IdentifyExpressionResponse;
  responses: Array<JSX.Element | string>;
  saveState: boolean;
}

class UnstyledEvaluator extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      evaluatorInputText: 'renderAsHtml tutorial',
      responses: [],
      saveState: false,
    };
  }

  render() {
    return (
      <div className="Evaluator">
        <List className={this.props.classes.responsesClass}>
          {this.state.responses.map((response, index) => (
            <ListItem key={index}> {response} </ListItem>
          ))}
        </List>
        <textarea
          className="Evaluator-Input-Text"
          value={this.state.evaluatorInputText}
          onChange={this.updateEvaluatorInputText}
          spellCheck={false}
        />
        <Toolbar className={this.props.classes.toolbarClass}>
          <Button
            className={this.getParseButtonClass()}
            onClick={this.handleParse}
          >
            {' '}
            Parse{' '}
          </Button>
          <Button
            className={this.getIdentifyButtonClass()}
            onClick={this.handleIdentify}
          >
            {' '}
            Identify{' '}
          </Button>
          {this.getIdentifiedExpression() && (
            <Button onClick={this.handleEvaluate}> Evaluate </Button>
          )}
          {this.getIdentifiedBindings() && (
            <Button
              className={this.getSaveButtonClass()}
              onClick={this.handleSave}
            >
              {' '}
              Save{' '}
            </Button>
          )}
        </Toolbar>
      </div>
    );
  }

  private handleParse = () => {
    if (this.state.parseState && this.state.parseState.tag === 'Success') {
      return;
    }
    const expressionRequest = JSON.stringify({
      parseText: this.state.evaluatorInputText,
    });
    fetch(getBackendEndpoint(this.props.endpoint) + '/api/parse', {
      body: expressionRequest,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Request-Id': Uuid.v4(),
      },
      method: 'PUT',
      mode: getMode(this.props.endpoint),
    }).then(response => {
      if (response.ok) {
        response.json().then(responseJson => {
          this.setState({
            parseState: { tag: 'Success', contents: responseJson },
          });
        });
      } else {
        response.text().then(text => {
          this.setState({
            parseState: {
              tag: 'Error',
              contents: 'Something went wrong! ' + text,
            },
            responses: this.state.responses.concat(text),
          });
        });
      }
    });
  };

  private handleIdentify = () => {
    if (this.state.identifyState) {
      switch (this.state.identifyState.tag) {
        case 'IdentifiedExpression':
          return;
        case 'PartiallyIdentifiedExpression':
          this.callIdentifyExpression(
            JSON.stringify({
              tag: 'IdentifyExpressionRequest',
              contents: this.state.identifyState.contents,
            })
          );
          return;
        case 'IdentifiedBinding':
          return;
        case 'PartiallyIdentifiedBinding':
          this.callIdentifyBindings(
            JSON.stringify({
              tag: 'IdentifyBindingRequest',
              contents: this.state.identifyState.contents,
            })
          );
          return;
        case null:
          return;
      }
    } else if (
      this.state.parseState &&
      this.state.parseState.tag === 'Success'
    ) {
      if (this.state.parseState.contents.tag === 'ParsedExpression') {
        this.callIdentifyExpression(
          JSON.stringify({
            tag: 'ParseExpressionResponse',
            contents: this.state.parseState.contents.contents,
          })
        );
      } else {
        this.callIdentifyBindings(
          JSON.stringify({
            tag: 'ParseBindingResponse',
            contents: this.state.parseState.contents.contents,
          })
        );
      }
    }
  };

  private handleEvaluate = () => {
    const identified = this.getIdentifiedExpression();
    if (!identified) {
      return;
    }

    const request = JSON.stringify({ expression: identified });
    fetch(getBackendEndpoint(this.props.endpoint) + '/api/evaluate', {
      body: request,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Request-Id': Uuid.v4(),
      },
      method: 'PUT',
      mode: getMode(this.props.endpoint),
    }).then(response => {
      if (response.ok) {
        response.json().then(responseJson => {
          const jsx = getJSX(responseJson.evaluatedExpression);
          if (jsx) {
            this.setState({
              responses: this.state.responses.concat([jsx]),
            });
            return;
          }

          const exprBinder: Expr<Binder> = responseJson.evaluatedExpression;
          const exprString = mapExpr(binder => binder.name, exprBinder);
          this.setState({
            responses: this.state.responses.concat([
              prettyPrintExpr(exprString),
            ]),
          });
        });
      } else {
        // do nothing
      }
    });
  };

  private handleSave = () => {
    const identified = this.getIdentifiedBindings();
    if (!identified) {
      return;
    }

    if (this.state.saveState) {
      return;
    }

    const request = JSON.stringify({ binding: identified });
    fetch(getBackendEndpoint(this.props.endpoint) + '/api/save', {
      body: request,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Request-Id': Uuid.v4(),
      },
      method: 'PUT',
      mode: getMode(this.props.endpoint),
    }).then(response => {
      if (response.ok) {
        response.json().then(responseJson =>
          this.setState({
            responses: this.state.responses.concat(
              <p>
                Saved <i> {responseJson.name} </i>{' '}
              </p>
            ),
            saveState: true,
          })
        );
      } else {
        response.text().then(text =>
          this.setState({
            responses: this.state.responses.concat('Save failed! ' + text),
          })
        );
      }
    });
  };

  private getParseButtonClass = (): string => {
    if (this.state.parseState) {
      switch (this.state.parseState.tag) {
        case 'Success':
          return this.props.classes.buttonDone;
        case 'Error':
          return this.props.classes.buttonError;
        default:
          throw Error('Impossible');
      }
    } else {
      return this.props.classes.buttonIdle;
    }
  };

  private getIdentifyButtonClass = (): string => {
    if (
      this.state.identifyState &&
      (this.state.identifyState.tag === 'IdentifiedBinding' ||
        this.state.identifyState.tag === 'IdentifiedExpression')
    ) {
      return this.props.classes.buttonDone;
    } else {
      return this.props.classes.buttonIdle;
    }
  };

  private getSaveButtonClass = (): string => {
    if (this.state.saveState) {
      return this.props.classes.buttonDone;
    } else {
      return this.props.classes.buttonIdle;
    }
  };

  private callIdentifyExpression = (request: string) => {
    fetch(
      getBackendEndpoint(this.props.endpoint) + '/api/identify/expression',
      {
        body: request,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-Request-Id': Uuid.v4(),
        },
        method: 'PUT',
        mode: getMode(this.props.endpoint),
      }
    ).then(response => {
      if (response.ok) {
        response.json().then(responseJson => {
          this.setState({
            identifyState: responseJson,
          });
          if (responseJson.tag === 'PartiallyIdentifiedExpression') {
            this.setState({
              responses: this.state.responses.concat(
                'An identifier is still ambiguous! ' +
                  JSON.stringify(responseJson.contents)
              ),
            });
          }
        });
      } else {
        response.text().then(text =>
          this.setState({
            responses: this.state.responses.concat('Identify failed! ' + text),
          })
        );
      }
    });
  };

  private callIdentifyBindings = (request: string) => {
    fetch(getBackendEndpoint(this.props.endpoint) + '/api/identify/binding', {
      body: request,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Request-Id': Uuid.v4(),
      },
      method: 'PUT',
      mode: getMode(this.props.endpoint),
    }).then(response => {
      if (response.ok) {
        response.json().then(responseJson => {
          this.setState({
            identifyState: responseJson,
          });
          if (responseJson.tag === 'PartiallyIdentifiedBinding') {
            this.setState({
              responses: this.state.responses.concat(
                'An identifier is still ambiguous!' +
                  JSON.stringify(responseJson.contents)
              ),
            });
          }
        });
      } else {
        response.text().then(text =>
          this.setState({
            responses: this.state.responses.concat('Identify failed! ' + text),
          })
        );
      }
    });
  };

  private updateEvaluatorInputText = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    this.setState({
      evaluatorInputText: event.target.value,
      identifyState: undefined,
      parseState: undefined,
      saveState: false,
    });
  };

  private getIdentifiedExpression = (): Expr<Binder> | null => {
    if (this.state.identifyState) {
      switch (this.state.identifyState.tag) {
        case 'IdentifiedExpression':
          return this.state.identifyState.contents;
        default:
          return null;
      }
    } else {
      return null;
    }
  };

  private getIdentifiedBindings = (): [Binder, Bound<Binder>] | null => {
    if (this.state.identifyState) {
      switch (this.state.identifyState.tag) {
        case 'IdentifiedBinding':
          return this.state.identifyState.contents;
        default:
          return null;
      }
    } else {
      return null;
    }
  };
}

const styles = (theme: Theme) =>
  createStyles({
    buttonDone: {
      color: 'MediumSeaGreen',
    },
    buttonError: {
      color: 'red',
    },
    buttonIdle: {
      color: 'grey',
    },
    responsesClass: {
      fontFamily: 'monospace',
      fontSize: 18,
      height: 200,
      overflowY: 'scroll',
    },
    toolbarClass: {
      height: '10%',
    },
  });

// tslint:disable-next-line:variable-name
export const Evaluator = withStyles(styles)(UnstyledEvaluator);
