import { List, ListItem } from '@material-ui/core';
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from '@material-ui/core/styles';
import * as React from 'react';
import * as Uuid from 'uuid';
import { Binder } from './Binder';
import { Bound, mapBound, prettyPrintBound } from './Bound';
import { Endpoint, getBackendEndpoint, getMode } from './Endpoint';

interface Props extends WithStyles<typeof styles> {
  endpoint: Endpoint;
}

interface State {
  bindings: Array<[Binder, Bound<Binder>]>;
  errorMessage: string;
}

class UnstyledBrowser extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      bindings: [],
      errorMessage: '',
    };
  }

  render() {
    return (
      <div className="Browser">
        <List className={this.props.classes.itemsClass}>
          {this.state.bindings.map(it => this.bindingToListItem(it[0], it[1]))}
        </List>
        <p>{this.state.errorMessage}</p>
      </div>
    );
  }

  componentDidMount() {
    this.fetchBrowserItems();
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.endpoint !== prevProps.endpoint) {
      this.fetchBrowserItems();
    }
  }

  renderError = (message: string) => {
    this.setState({ errorMessage: message });
  };

  fetchBrowserItems = () => {
    fetch(getBackendEndpoint(this.props.endpoint) + '/api/lookup', {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Request-Id': Uuid.v1(),
      },
      method: 'GET',
      mode: getMode(this.props.endpoint),
    }).then(response => {
      if (response.ok) {
        response.json().then(responseJson =>
          this.setState({
            bindings: responseJson.bindings,
            errorMessage: '',
          })
        );
      } else {
        response.text().then(text =>
          this.setState({
            bindings: [],
            errorMessage: text,
          })
        );
      }
    });
  };

  private bindingToListItem = (binder: Binder, bound: Bound<Binder>) => {
    return (
      <ListItem key={binder.guid} style={{ display: 'inline-block' }}>
        <div>{binder.guid}</div>
        {binder.name} = {prettyPrintBound(mapBound(it => it.name, bound))}
      </ListItem>
    );
  };
}

const styles = (theme: Theme) =>
  createStyles({
    itemsClass: {
      fontFamily: 'monospace',
    },
  });

// tslint:disable-next-line:variable-name
export const Browser = withStyles(styles)(UnstyledBrowser);
